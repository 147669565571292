<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Отзывы</div>
        <div class="tools">
          <router-link class="btn btn-accent" to="/review/create"
            >Создать отзыв</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <reviews-table />
      </div>
    </div>
  </div>
</template>

<script>
import ReviewsTable from "../../feature/partners/tables/ReviewsTable.vue";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { ReviewsTable },
  name: "partners-page",
  setup() {
    const { getRight } = useCheckRights();

    return {
      getRight,
    };
  },
};
</script>

<style>
</style>