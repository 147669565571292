<template>
  <div class="filters">
    <div class="filter_block">
      <div>
        <custom-input
            :delay="400"
            label="Имя"
            name="name"
            v-model="searchParams.nameField"
            clear
        />
        <custom-input
            :delay="400"
            label="Партнёр"
            name="partner"
            v-model="searchParams.partnerField"
            clear
        />
        <custom-input
            :delay="400"
            label="Эксперт"
            name="partner"
            v-model="searchParams.expertField"
            clear
        />
        <custom-input
            :delay="400"
            label="Текст"
            name="partner"
            v-model="searchParams.text"
            clear
        />
      </div>
      <div class="date_block">
        <custom-input v-model="searchParams.dateFrom" type="date" :inputWidth="80" :labelWidth="20" label="От"
                      name="dateFrom"/>
        <custom-input v-model="searchParams.dateTo" type="date" :inputWidth="80" :labelWidth="20" label="До"
                      name="dateTo"/>
      </div>
    </div>
    <div class="search_btn search__clear-btn">
      <button @click="clearAllSearch" type="button" class="btn btn-w btn-sm">
        Очистить
      </button>
    </div>
  </div>
  <div v-if="!loadingTable">
    <div v-if="shownData.length">
      <base-table
          v-if="shownData.length"
          :columns="shownFields.value"
          :rows="shownData"
      />
      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomSelectFilter from "@/components/Tables/FiltersComponents/CustomSelectFilter.vue";
import useCheckRights from "@/mixins/useCheckRights";
import sortData from "@/mixins/sortData";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "partners-table",
  components: {
    BaseTable,
    Preloader,
    CustomInput,
    TablePagination,
    CustomSelectFilter,
  },
  setup() {
    const store = useStore();

    const {getRight} = useCheckRights();
    const profile = computed(() => store.state.profile.profile);

    const countOnPage = ref(15),
        activeFilter = ref(false),
        isPreloader = ref(false),
        searchParams = reactive({
          expertField: "",
          partnerField: "",
          nameField: "",
          text: "",
          dateFrom: "",
          dateTo: "",
        });
    const loadingTable = computed(() => store.state.ui.loadingTable);
    const reviews = computed(() => store.state.partners.reviews),
        rowData = computed(() => {
          return reviews.value.map((review) => {
            return {
              id: review.id,
              name: review.title,
              text: review.description,
              date: review.date,
              new: review.status,
              expert: review.expert?.title,
              partner: review.partner?.title,
              expert_id: review.expert?.id,
              partner_id: review.partner?.id,
              edit: review.id,
            };
          });
        });

    let reviewFields = reactive({
      value: [
        {
          field: "partner_renderer",
          headerName: "",
        },
        {
          field: "partner",
          headerName: "Партнер",
          link: true,
          linkId: "partner_id",
          dest: "partner",
        },
        {
          field: "expert",
          headerName: "Эксперт",
          link: true,
          linkId: "expert_id",
          dest: "expert",
        },
        {
          field: "name",
          headerName: "Имя",
        },
        {
          field: "review",
          headerName: "Текст",
        },
        {
          field: "date",
          headerName: "Дата",
        },
        {
          field: "edit",
          headerName: "",
          dest: "review",
        },
      ]
    });

    let shownFields = reactive({
      value: []
    });

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.expertField && searchParams.expertField.length >= 3) {
          data = data.filter((el) =>
              el.expert.toLowerCase().includes(searchParams.expertField.toLowerCase())
          );
        }

        if (searchParams.partnerField && searchParams.partnerField.length >= 3) {
          data = data.filter((el) =>
              el.partner.toLowerCase().includes(searchParams.partnerField.toLowerCase())
          );
        }

        if (searchParams.nameField && searchParams.nameField.length >= 3) {
          data = data.filter((el) => el.name.toLowerCase().includes(searchParams.nameField.toLowerCase()));
        }

        if (searchParams.text && searchParams.text.length >= 3) {
          data = data.filter((el) =>
              el.text.toLowerCase().includes(searchParams.text.toLowerCase())
          );
        }

        if (searchParams.dateFrom || searchParams.dateTo) {
          data = data.filter((el) => {
            let date = Date.parse(el.date);

            if (date >= (Date.parse(searchParams.dateFrom) || 0) && date <= (Date.parse(searchParams.dateTo) || Number.MAX_SAFE_INTEGER)) {
              return true;
            }
          });
        }

        data = sortData(data, 'new', 'up');

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const clearAllSearch = () => {
      searchParams.nameField = "";
      searchParams.partnerField = "";
      searchParams.expertField = "";
      searchParams.text = "";
      searchParams.dateFrom = "";
      searchParams.dateTo = "";
    };

    onBeforeMount(() => {

      if (!getRight("partnersList")) {
        shownFields.value = reviewFields.value.filter((el) => el.field !== 'partner_renderer' && el.field !== 'partner')
      } else {
        shownFields.value = reviewFields.value;
      }

      store.commit("ui/setLoadingTable", true);
      store
          .dispatch("partners/getReviews")
          .finally(() => store.commit("ui/setLoadingTable", false));
    });

    watch(
        () => [countOnPage.value, searchParams.nameField,
          searchParams.partnerField, searchParams.text,
          searchParams.dateTo, searchParams.dateFrom, searchParams.expertField],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    watch(() => [profile.value], () => {
      if (!getRight("partnersList")) {
        shownFields.value = reviewFields.value.filter((el) => el.field !== 'partner_renderer' && el.field !== 'partner')
      } else {
        shownFields.value = reviewFields.value;
      }
    })

    return {
      countOnPage,
      isPreloader,
      reviews,
      searchParams,
      activeFilter,
      loadingTable,

      rowData,

      shownData,
      shownFields,

      changePage,
      clearAllSearch,
      updatePagination,
      totalResults

    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 40px 10px;
}

.filters {
  display: flex;
  row-gap: 5px;
  flex-direction: column;

}

.filter_block {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}

.filter_block .form-group {
  width: 400px;
}

.date_block .form-group {
  width: 350px;
}

.search_btn {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .filter_block {
    flex-direction: column;
    width: 100%;
  }

  .filter_block .form-group {
    width: 100%;
  }
}
</style>
